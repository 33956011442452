import React from 'react';
import '../../App.css';
import MainPage from '../MainPage';

function Home () {
    return (
        <> 
            <MainPage />
        </>
    );
}

export default Home;